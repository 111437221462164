import React, { Component } from "react"
import FixedMenu from "../components/FixedMenu" 
import ImageCard from "../components/ImageCard"
import SiteContext from "../context/SiteContext"
import SEO from "../components/seo"

class ProductCategoriesPage extends Component {

    static contextType = SiteContext;

    constructor(props) {
        super(props)
        this.state = {
            products: [],
            categories: [],
            site: '',
        }
    }

    componentDidMount() {

        let site = 'finestone'
        
        this.context.changeSite(site)

        const { productData, productCategories } = this.props.pageContext
        let products = []
        let categories = []
        if (typeof productData !== 'undefined') {
            products = productData.data.craft.entries
        }

        if (typeof productCategories !== 'undefined') {
            categories = productCategories.data.craft.categories
        }
        const sortedCategories = categories.sort((a, b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0));

        sortedCategories.map( cat => {
            cat.numberOfRelatedProducts = 0;
            if (cat.children.length > 0){
                cat.children.map( children => {
                    products.map( product => {
                        product.productCategory.map ( prodCat => {
                            if (prodCat.id === children.id) {
                                cat.numberOfRelatedProducts++; 
                            }
                        })
                    })
                })
            }
            else {
                products.map( product => {
                    product.productCategory.map ( prodCat => {
                        if (prodCat.id === cat.id) {
                            cat.numberOfRelatedProducts++; 
                        }
                    })
                })
            }
           
        })


        this.setState({
            products,
            categories: sortedCategories,
            site
        })
    }

    render() {
        return (
            <SiteContext.Consumer>
                {siteContext => (
                    <>
                        <SEO title="Products" />
                        <div className="wrapper app-header-style-text">
                            <div>
                                <div className="app-header">
                                    <div className="app-header-content">
                                        <h1 className="app-header__title">Products</h1> 
                                    </div>
                                </div>
                                <div className="image-card__wrapper">
                                {
                                    this.state.categories.map(prodCat => {
                                        if (prodCat.level === 1) {
                                            // console.log("prodCat ", prodCat);
                                            let subtitle = prodCat.numberOfRelatedProducts + ' Products';
                                            return (
                                                <ImageCard 
                                                    imagePath={`url(${prodCat.imagePath}?width=940)`}
                                                    url={`/products/${prodCat.slug}`}
                                                    title={prodCat.title}
                                                    subtitle={subtitle}
                                                />
                                            )
                                        }
                                    })
                                }     
                                </div>                       
                            </div>
                            <FixedMenu active="products" site={siteContext.site} /> 
                        </div>
                    </>
                )}
            </SiteContext.Consumer>
        )
    }
}

export default ProductCategoriesPage 